@import "src/scss/module";

.feed {
  :global {
    .js-container {
      font-family: var(#{--bs-font-family});
    }

    .js-columns-container {
      margin-top: 0;

      @include media-breakpoint-up(md) {
        margin-top: $spacer * 4;
      }
    }

    .js-total-count {
      font-weight: $font-weight-bold;
    }

    .js-form {
      border: 0;
      padding: 0;

      &-input-wrapper {
        flex-direction: column;
        gap: $spacer * 0.5;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          gap: $spacer;
        }

        .js-button {
          background-color: var(#{--bs-secondary});
          border-color: var(#{--bs-secondary});
          margin-left: 0;
          font-weight: $font-weight-bold;
          border-radius: var(#{--bs-border-radius});

          &:hover {
            background-color: var(#{--bs-secondary-active});
            border-color: var(#{--bs-secondary-active});
          }
        }
      }
    }

    .js-input {
      border-radius: var(#{--bs-border-radius});
      padding: $spacer * 0.5 $spacer * 0.75;

      @include media-breakpoint-up(md) {
        padding: $spacer;
      }

      &-wrapper {
        margin: 0;
        width: auto;

        &-query {
          width: 100%;
        }

        &-radius {
          @include media-breakpoint-up(md) {
            flex-basis: 200px;
          }
        }
      }
    }

    .js-filters-toggle-button {
      background-color: var(#{--bs-primary});
      border-color: var(#{--bs-primary});
      border-radius: var(#{--bs-border-radius-sm});

      &:hover {
        background-color: var(#{--bs-primary-active});
        border-color: var(#{--bs-primary-active});
      }
    }

    .js-filter {
      &-box {
        border: 0;
        padding: 0;
      }

      &-title {
        background-color: var(#{--bs-primary});
        color: $white;
        padding: $spacer * 0.5 $spacer * 1;
        border-radius: var(#{--bs-border-radius-sm});
        font-weight: $font-weight-bold;
        margin-bottom: 0;

        &-closed {
          opacity: 1;

          button {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        button {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &:after {
            content: "";
            display: block;
            width: 24px;
            height: 25px;
            transition: $transition-base;
            background-image: escape-svg(
              url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6 13.5L12 19.5L18 13.5M6 5.5L12 11.5L18 5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
            );
          }
        }
      }

      &-values-section {
        padding: 0;
        margin: 0;
      }

      &-value {
        width: 100%;
        padding: $spacer * 0.5 $spacer;
        border-bottom: 1px solid #d6d6d6;

        &-name {
          margin-right: auto;
          margin-left: $spacer;
        }

        &-content {
          width: 100%;

          .form-check-input {
            width: $spacer * 1.75;
            height: $spacer * 1.75;
            margin: 0;
          }
        }
      }

      &-count {
        background-color: var(#{--bs-quaternary});
      }
    }

    .js-results {
      &-details {
        &-employer-logo {
          width: 100px;
          // height: 100px;
          max-width: 25%;
          margin-bottom: 1rem;
            position: absolute;
            top: 0px;
            right: 0px;
            margin: $spacer;
            border-radius: 4px;
          
        }

        &-location {
          display: flex;
          width: 100%;
          gap: $spacer * 0.5;
          margin-bottom: $spacer;
          margin-top: 1ch;

          &::before {
            display: block;
            width: 24px;
            height: 24px;
            content: "";
            background-image: escape-svg(
              url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 18.6C11.8333 18.6 11.6707 18.575 11.512 18.525C11.3533 18.475 11.2077 18.4 11.075 18.3C9.725 17.25 8.375 15.929 7.025 14.337C5.675 12.745 5 11.016 5 9.15C5 7.96667 5.21267 6.929 5.638 6.037C6.06334 5.145 6.60867 4.39933 7.274 3.8C7.94067 3.2 8.69067 2.75 9.524 2.45C10.3573 2.15 11.1827 2 12 2C12.8167 2 13.6417 2.15 14.475 2.45C15.3083 2.75 16.0583 3.2 16.725 3.8C17.3917 4.4 17.9377 5.146 18.363 6.038C18.7883 6.93 19.0007 7.96733 19 9.15C19 11.0167 18.325 12.746 16.975 14.338C15.625 15.93 14.275 17.2507 12.925 18.3C12.7917 18.4 12.6457 18.475 12.487 18.525C12.3283 18.575 12.166 18.6 12 18.6ZM12 11C12.55 11 13.021 10.804 13.413 10.412C13.805 10.02 14.0007 9.54933 14 9C14 8.45 13.804 7.979 13.412 7.587C13.02 7.195 12.5493 6.99933 12 7C11.45 7 10.979 7.196 10.587 7.588C10.195 7.98 9.99934 8.45067 10 9C10 9.55 10.196 10.021 10.588 10.413C10.98 10.805 11.4507 11.0007 12 11ZM6 22C5.71667 22 5.479 21.904 5.287 21.712C5.095 21.52 4.99934 21.2827 5 21C5 20.7167 5.096 20.479 5.288 20.287C5.48 20.095 5.71734 19.9993 6 20H18C18.2833 20 18.521 20.096 18.713 20.288C18.905 20.48 19.0007 20.7173 19 21C19 21.2833 18.904 21.521 18.712 21.713C18.52 21.905 18.2827 22.0007 18 22H6Z" fill="#E50B7E"/></svg>')
            );
          }
        }

        &-salary-package {
          display: inline-flex;
          background-color: var(#{--bs-primary});
          color: $white;
          padding: 0 6px;
          border-radius: var(#{--bs-border-radius-sm});
          margin-right: 1ch;
          margin-bottom: 1ch;
          margin-top: 1ch;
        }

        &-categories {
          display: inline;
        }

        &-category {
          display: inline;
        }
      }

      &-category-value {
        display: inline-flex;
        background-color: var(#{--bs-primary});
        color: $white;
        padding: 0 6px;
        border-radius: var(#{--bs-border-radius-sm});
        margin-bottom: 1ch;

        &:after {
          display: none;
        }

        &[data-currency-="true"] {
          display: none;
        }
      }
    }

    .js-result {
      position: relative;
      padding: 1em;

      @include media-breakpoint-up(md) {
        padding: 2em;
      }

      &-details {
        display: inline;
      }

      &-title {
        font-size: $h4-font-size;
        font-weight: $font-weight-bold;
        color: var(#{--bs-primary});
        padding-top: 0;

        &.has-logo {
          max-width: 70%;
        }

        a {
          color: inherit;
        }
      }

      &-links {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $spacer * 0.3333;
        
        @include media-breakpoint-up(lg) {
          gap: $spacer * 0.5;
          justify-content: flex-end;
        }

        [role="button"] {
          height: 48px;
          font-weight: $font-weight-bold;
          max-width: 100%;
        }

        time {
          opacity: 0.75;
          text-align: left;
          width: 100%;
          margin-bottom: $spacer;

          @include media-breakpoint-up(lg) {
            width: auto;
            margin-bottom: 0;
          }
        }
      }
    }

    .js-filter-box[data-employers="true"] {
      display: none;
    }

    .js-pagination {
      padding: $spacer * 3 0;
      border: 0;

      &:empty {
        display: none;
      }

      .js-button {
        width: 100%;
        display: block;
        margin: 0;
        background-color: $white;
        color: var(#{--bs-secondary});
        border-color: var(#{--bs-secondary});
        border-width: 2px;
        font-weight: $font-weight-bold;
        padding: $spacer * 0.75 $spacer;
        font-family: var(#{--bs-font-family-button});
        border-radius: var(#{--bs-border-radius});

        &:hover {
          background-color: var(#{--bs-secondary});
          border-color: var(#{--bs-secondary});
          color: $white;
        }
      }
    }
  }
}
