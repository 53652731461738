@import "src/scss/module";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.hero {
  position: relative;
  color: $white;
  padding: 1rem 0;

  :global {
    @include media-breakpoint-up(md) {
      .row {
        margin-right: calc(-1.5* var(--bs-gutter-x));
        margin-left: calc(-1.5* var(--bs-gutter-x));
        > * {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
    }
    .card {
      height: 350px;
      @include media-breakpoint-up(md) {
        height: 600px;
      }
      .card-header {
        background: none;
        z-index: 1;
      }
      .card-body {
        z-index: 1;
        border: 0;
        transition: $transition-base;
        .btn {
          box-shadow: 0px 4px 4px 0px #00000040;
          font-size: 1.2rem;
          font-weight: 600;
          @include media-breakpoint-up(md) {
            padding: 10px 30px 10px 30px;
          }
        }
      }
      &:hover {
        .card-body {
          @include media-breakpoint-up(md) {
            margin-bottom: 0.6rem; 
          }
        }
      }
    }
    .js-container {
      height: auto;
      .js-panel-border {
        padding: 0.5rem;
        border: none;
        background-color: white;
        box-shadow: 0px 4px 4px 0px #00000040;
      }
      .js-input {
        border: none;
        padding: 0.75rem 0.75rem;
      }
      .js-button {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
        &:hover {
          background-color: var(--bs-secondary);
          border-color: var(--bs-secondary);
        }
      }
    }
  }

  .bg_img,
  .img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bg_img {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.7;
      transition: $transition-base;
    }
  }

  .card {
    &:hover {
      .bg_img {
        &:after {
          opacity: 0.9;
        }
      }
    }
  }

  .card_one {
    .bg_img {
      &:after {
        background: linear-gradient(0deg, rgba(0, 158, 227, 1), rgba(0, 158, 227, 1)),
      }
    }
    h1 {
      font-size: 40px;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        font-size: 70px;
      }
    }
    h2 {
      font-weight: 300;
      u {
        font-weight: 600;
      }
    }
  }

  .card_two {
    .bg_img {
      &:after {
        background: #1c1464,
      }
    }
    h2, h3 {
      font-family: "Oswald", sans-serif;
      text-transform: uppercase;
      margin: 0;
      line-height: 1;
      white-space: nowrap;
    }
    h2 {
      font-size: 8vw;
      margin-top: 0.75rem;
      @include media-breakpoint-up(sm) {
        font-size: 30px;
      }
      @include media-breakpoint-up(md) {
        font-size: 48px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 42px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 48px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 58px;
      }
    }
    h3 {
      font-size: 10.4vw;
      letter-spacing: -1px;
      @include media-breakpoint-up(sm) {
        font-size: 38px;
      }
      @include media-breakpoint-up(md) {
        font-size: 62px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 55px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 62px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 74px;
      }
    }
  }
}